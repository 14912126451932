.content {
    &.content--margin-top {
        margin-top: 40px;
    }

    &.content--margin-bottom {
        margin-bottom: 40px;
    }

    &.content--heading.content--margin-bottom {
        margin-bottom: 20px;
    }
}
