.page {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;

    &__wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
    }

    &__header {
        position: relative;

        &::before {
            content: "";
            position: fixed;
            z-index: $z-index__header;
            top: 0; right: -32px; left: -32px;
            height: $header__height--mobile;
            background-color: $header__background-color;
            box-shadow: $header__box-shadow;

            @include media-breakpoint-up(lg) {
                height: $header__height--unpinned;
            }

            @include media-breakpoint-up(xxl) {
                height: $header__height;
                transition: height .3s ease-in-out;
            }

            html.headroom--unpinned & {
                @include media-breakpoint-up(xxl) {
                    height: $header__height--unpinned;
                }
            }
        }

        > .container-xxl{

            @include media-breakpoint-down(sm) {
                padding-left: 0;
                padding-right: 15px;
            }

            @media (max-width: 400px) {
                padding-right: 0;
            }
        }

        @include container-child-combinator {
            &:first-of-type {
                position: fixed;
                z-index: $z-index__header + 1;
                top: 0; right: 0; left: 0;
                display: flex;
                flex-direction: row;
                pointer-events: none;
                //gap: 0 24px;
                @if $main-navigation__mode == off-canvas {
                    //padding-right: $header__height--mobile + 16px;
                }
                height: $header__height--mobile;

                @if $main-navigation__mode == menu {
                    @media (max-width: #{$off-canvas__breakpoint - .02}) {
                        padding-right: $header__height--mobile + 16px;
                    }

                    @media (min-width: #{$off-canvas__breakpoint}) {
                        > * {
                            &:not(.menu-navigation) {
                                height: 55%;
                            }

                            &.menu-navigation {
                                height: 45%;
                            }
                        }
                    }
                }

                >*{
                    pointer-events: auto;
                }

                @include media-breakpoint-up(lg) {
                    @if $main-navigation__mode == off-canvas {
                        //padding-right: $header__height--unpinned + 64px;
                    }
                    height: $header__height--unpinned;
                }

                @include media-breakpoint-up(xxl) {
                    @if $main-navigation__mode == off-canvas {
                        //padding-right: $header__height + 64px;
                    }
                    height: $header__height;
                    transition: padding-right .3s ease-in-out, height .3s ease-in-out;
                }

                html.headroom--unpinned & {
                    @include media-breakpoint-up(xxl) {
                        @if $main-navigation__mode == off-canvas {
                            //padding-right: $header__height--unpinned + 64px;
                        }
                        height: $header__height--unpinned;
                    }
                }
            }
        }

        .off-canvas{

            &__toggle--open{
                height: 54px;

                @include media-breakpoint-down(lg) {
                    width: 55px;
                }

                span.lines,
                span.lines:before,
                span.lines:after{
                    border-radius: 5px;
                    transition: top .2s ease-in-out,width .2s ease-in-out,background-color .25s linear,transform .25s linear;
                }

                span.lines{
                    width: 35px;
                }

                &:hover,
                &:focus {

                    span.lines:before{
                        top: -10px;
                    }

                    span.lines:after{
                        top: 10px;
                    }
                }

                @each $breakpoint, $container-padding-size in $container-padding-sizes {

                    @include media-breakpoint-down(sm) {
                        right: 15px;
                    }

                    @media (max-width: 400px) {
                        right: 0;
                    }

                    @include media-breakpoint-up(#{$breakpoint}) {
                        right: $container-padding-size;
                    }
                }

                html.headroom--unpinned &{
                    height: calc($off-canvas__toggle-size / 1.2);
                }

                span.text{
                    display: none;
                }
            }

            &__trigger:checked + .off-canvas__wrapper .off-canvas__toggle--open{

                @include hover-focus-visible {

                    span.lines{
                        width: 26px;
                    }
                }
            }

            &__toggle--close{
                top: 7px;
                background-color: $color__primary-2;

                span{
                    transition: color .2s linear,width .2s ease-in-out,background-color 0s linear .25s;
                }

                @include hover-focus-visible{

                    span{
                       width: 25px;
                    }
                }

                @media (min-width: 670px) {
                    height: calc($off-canvas__toggle-size / 1.2);
                    top: 13px;
                    right: 585px;
                }
            }

            &__inner{
                padding-top: 72px;

                @include media-breakpoint-up(lg) {
                    padding-top: 86px;
                }

                @media (min-width: 670px) {

                    .navigation-open &{
                        overflow: visible;
                    }
                }

                .main-navigation{

                    @media (min-width: 670px) {
                        overflow: hidden;
                    }

                    &__item.main-navigation__item{

                        &--home > a{
                            color: $color__white;
                            text-decoration: none;

                            &:before{
                                filter: $filter__white;
                            }

                            @include hover-focus-visible{
                                text-decoration: underline;
                            }
                        }

                        &--header{
                            color: $color__white;
                            border-bottom-color: $color__primary-3;
                        }
                    }

                    &__item.main-navigation__item--active {

                        > a,
                        > button,
                        button > a{
                            color: #91D9F8;
                            background-position: 100% 100%,0 0;

                            &:hover,
                            &:focus {
                                background-position: 0 100%,0 0;
                            }
                        }
                    }

                    &__link {
                        color: $color__white;
                        background-image: linear-gradient(to right, $color__primary-3 50%, transparent 50%),linear-gradient(to bottom, transparent calc(100% - 0.0625rem), $color__primary-3 calc(100% - 0.0625rem));

                        > span[aria-hidden]{
                            position: relative;
                            right: 4px;
                            filter:$filter__white;
                            transition: right .3s ease-in-out;
                        }

                        @include hover-focus-visible{

                            > span:not([aria-hidden]){
                                text-decoration: underline;
                            }
                        }
                    }

                    &__button{
                        color: $color__white;
                        background-image: linear-gradient(to right, $color__primary-3 50%, transparent 50%),linear-gradient(to bottom, transparent calc(100% - 0.0625rem), $color__primary-3 calc(100% - 0.0625rem));

                        > span[aria-hidden]{
                            filter:$filter__white;
                        }

                        &.main-navigation__button--prev{
                            color: $color__white;
                            text-decoration: none;

                            > span[aria-hidden]{
                                filter:$filter__white;
                            }

                            @include hover-focus-visible{
                                text-decoration: underline;
                            }
                        }

                        @include hover-focus-visible{

                            .main-navigation__link{

                                > span[aria-hidden]{
                                    right: 0;
                                }

                                > span:not([aria-hidden]){
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }

            &__footer{
                margin-left: -24px;
                margin-right: -24px;
                background-color: $color__primary;
                gap: 8px;

                .button{
                    position: relative;
                    top: 0;
                    border: none;
                    padding: 8px 2px 8px 10px;
                    background-color: transparent;
                    transition: top .2s ease-in-out;

                    &:first-child img{
                        padding: 3px;
                    }

                    img{
                        height: 30px;
                        filter: $filter__white;
                    }

                    @include hover-focus-visible{
                        background-color: transparent;
                        top: -4px;

                        img{
                            filter: $filter__white;
                        }
                    }
                }

                span{

                    &:not(.icon){
                        height: 0;
                        width: 0;
                        visibility: hidden;
                    }
                }
            }
        }

    }

    &__main {
        flex-shrink: 0;

        // position: relative;
        // padding-top: 64px;
        // scroll-margin-top: $header__height--mobile + 64px;

        // @include media-breakpoint-up(lg) {
        //     scroll-margin-top: $header__height--unpinned + 64px;
        // }

        // @include media-breakpoint-up(xxl) {
        //     scroll-margin-top: $header__height + 64px;
        // }

        // &.page__main--padding-top {
        //     padding-top: $header__height--mobile + 64px;

        //     @include media-breakpoint-up(lg) {
        //         padding-top: $header__height--unpinned + 64px;
        //     }

        //     @include media-breakpoint-up(xxl) {
        //         padding-top: $header__height + 64px;
        //     }
        // }

        body:not([data-template='home']):not(.has-banner-image):not(.has-banner-video):not(.has-breaking-ribbon) & {
            padding-top: $header__height--mobile;

            @include media-breakpoint-up(lg) {
                padding-top: $header__height--unpinned;
            }

            @include media-breakpoint-up(xxl) {
                padding-top: $header__height;
            }
        }

        body:not([data-template='home']):not(.has-banner-image):not(.has-banner-video).has-breaking-ribbon & {
            padding-top: $header__height--mobile + 64px;

            @include media-breakpoint-up(lg) {
                padding-top: $header__height + 19px;
            }
        }

        body[data-template='home'] &{

            .section--article-list,
            .section--top-topics-custom,
            .section--event-list,
            .section--countdown-custom,
            .section--embed-social-custom{

                &.section--padding-top.section--padding-bottom{

                    padding-top: 0;
                    padding-bottom: 4rem;
                }
            }

            header h2{
                @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 28px, 45px);
                position: relative;
                margin-top: 56px;
                font-weight: $font__weight--bold;
                line-height: normal;
                color:$color__primary;

                &:before{
                    content: "";
                    position: absolute;
                    top: -60px;
                    left: 0;

                    height: 8px;
                    width: 55px;
                    border-radius: 10px;
                    background-color: $color__primary;
                }
            }
        }
    }

    &__footer {
        margin-top: auto;

        a{

            @include focus-visible-within{
                text-decoration: none;
            }

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &.button-footer{
                border-color: $color__white;
                color: $color__white;
                background-color: $color__primary;

                &:hover,
                &:focus {
                    color: $color__primary!important;
                    background-color: $color__white;
                }
            }
        }

        .bg-gray-light .my-6{
            margin-top: 0!important;
            margin-bottom: 0!important;
            padding: 30px 0;
        }

        .logo-ribbon {

            .justify-content-center{
                justify-content: space-between!important;
            }

            a figure img {
                transform: none!important;
            }

            figure img {
                max-height: 70px;
            }
        }

        .d-flex {
            &.flex-column {
                gap: 55px 0;
            }
        }

        .row {
            gap: 48px 0;

            > div.flex-column:last-of-type{

                @include media-breakpoint-up(lg) {
                    align-items: flex-end;
                }

                > div{
                    justify-content: flex-end;

                    &:nth-of-type(2){
                        width: 350px;
                        max-width: 90%;

                        @media (min-width: 370px) {
                            max-width: 80%;
                        }

                        @include media-breakpoint-up(lg) {
                            max-width: 100%;
                        }

                        @include media-breakpoint-up(xl) {
                            width: 380px;
                        }
                    }
                }
            }
        }

        .whatsapp-link {
            position: absolute;
            right: 0;
            // margin-left: 48px;
            margin-left: auto;
            width: 40px;
            height: 40px;

            @include media-breakpoint-up(lg) {
                width: 44px;
                height: 44px;
            }

            a{
                position: fixed;
                z-index: $z-index__header - 2;
                right: $grid-gutter-width * .5;
                bottom: 30px;
                border-radius: 50%;

                padding: 9px;
                background-color: #25D366;
                box-shadow: 0 2px 5px rgba(0,0,0,.5);
                transition: background-color .15s linear, opacity .15s linear;

                @each $breakpoint, $container-padding-size in $container-padding-sizes {
                    @include media-breakpoint-up(#{$breakpoint}) {
                        right: $container-padding-size;
                    }
                }

                @include media-breakpoint-up(lg) {
                    padding: 11px;
                }

                img{
                    position: relative;
                    filter: $filter__white;
                    top: 0;
                    transition: top .2s ease-in-out;
                }

                @include hover-focus-visible{
                    img{
                        top: -4px;
                    }
                }

                &:hover,
                &:focus {
                    img{
                        top: -4px;
                    }
                }
            }
        }
    }
}
