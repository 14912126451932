header .banner{

    &--image,
    &--slider.banner--fullscreen{
        padding-top: 0;
    }

    &--image{

        picture{

            @include media-breakpoint-up(lg) {
                height: 500px;
            }

            @include media-breakpoint-up(xxl) {
                height: 550px;
            }
        }
    }

    &--slider.banner.banner--fullscreen {

        .splide{

            &__footer {
                position: relative;
                bottom: 70px;
                z-index: 100;

                @include media-breakpoint-up(md) {
                    bottom: 90px;
                }

                @include media-breakpoint-up(lg) {
                    bottom: 100px;
                }

                @include media-breakpoint-up(xl) {
                    bottom: 80px;
                }

                .banner__content{
                    position: absolute;
                    bottom: -60px;
                    left: 50%;

                    height: 44px;
                    width: 44px;
                    padding: 7px;
                    background-color: $color__white;
                    border: 1px solid $color__white;
                    transform: translate(-50%, -50%);
                    transition: all .2s ease-in-out;
                    box-shadow: 0 0.125rem 0.3125rem rgba(0,0,0,.5);

                    @include media-breakpoint-down(md) {
                        display: none;
                    }

                    @include media-breakpoint-up(xl) {
                        top: 50%;
                        bottom: auto;
                    }

                    @include hover-focus-visible{
                        background-color: $color__primary-2;

                        img{
                            filter: $filter__white;
                            top: 5px;
                        }
                    }

                    img{
                        position: relative;
                        top: 0;
                        height: 29px;
                        transform: rotate(90deg);
                        transition: all .2s ease-in-out;
                        filter: $filter__primary-2;
                    }
                }
            }

            &__arrows{
                flex: unset;

                button{
                    border: none;
                    background-color: transparent;

                    &.splide__toggle {
                        margin: 0;

                        @include media-breakpoint-up(md) {
                            margin: 0 12px;
                        }

                        img{
                            height: 26px;
                            transition: all .2s ease-in-out;
                        }

                        @include hover-focus-visible{

                            img{
                                transform: scale(1.15);
                            }
                        }
                    }

                    &:not(:last-child):not(.splide__toggle) {
                        margin-right: 0;
                    }

                    @include hover-focus-visible{

                        img{
                            filter: invert(34%) sepia(97%) saturate(768%) hue-rotate(166deg) brightness(101%) contrast(109%);
                        }
                    }
                }
            }

            &__pagination{
                margin-left: 20px;

                @include media-breakpoint-up(md) {
                    margin-left: 30px;
                }

                li button{
                    color: $color__white;

                    &.is-active{

                        &:before{
                            border-color: $color__white;
                            background-color: $color__white;
                        }

                        @include hover-focus-visible{

                            &:before{
                                background-color: #0085CC;
                                border-color: #0085CC;
                            }
                        }
                    }
                }
            }
        }

        figure{
            height: calc(100vh - 8px);
            height: calc((var(--vh, 1vh)*100) - 8px);

            &:before{
                content: "";
                position: absolute;
                display: none;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }
    }
}
