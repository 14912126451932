.splide {

    &:not(.splide--custom) {
        button.splide__arrow[disabled]{

        }

        .splide__pagination li{
            margin: 0 3px;

            button{
                width: 20px;

                @include media-breakpoint-up(sm) {
                    width: 26px;
                }

                &:before{
                    width: 85%;
                    height: 2px;
                    border-width: 1px;
                    border-radius: 10px;
                    transition: background-color .2s ease-in-out,border-color .2s ease-in-out, height .2s ease-in-out, border-width .4s ease-in-out;
                }

                &.is-active:before{
                    height: 6px;
                    border-width: 3px;
                    background-color: $color__primary;
                    border-color: $color__primary;
                }

                @include hover-focus-visible{

                    &:before{
                        background-color: #0085CC;
                        border-color: #0085CC;
                    }
                }
            }
        }

    }

    &__track {

    }

    &__list {

    }

    &__slide {

    }

    &__footer {

    }

    &__navigation {

    }

    &__arrows {

    }

    &__arrow {
        height: 40px;
        width: 40px;
        padding: 9px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            height: 30px;
            width: 30px;
            position: relative;
            left: 0;
            transition: left .2s ease-in-out;
        }

        body:not([data-template='home']) &{
            border: 1px solid $color__primary;
            padding: 8px;
        }

        @include hover-focus-visible{

            img{
                left: 5px;
            }

            body:not([data-template='home']) &{

                img{
                    filter: $filter__primary-2;
                }
            }
        }

        &--prev{

            @include hover-focus-visible{
            img{
                left: -5px;
            }
        }
        }
    }

    &__toggle {

    }

    &__pagination {

    }
}
